<template>
  <div id="rrapp" v-cloak>
<!--    <div>
      <el-divider content-position="center">请选择搜索条件</el-divider>
    </div>
    <div class="block">
      <el-row :gutter="24">
        <el-col :span="6" >
          <div class="grid-content">
            <span  style="margin-right: 5px;">用户唯一标识</span>
            <el-input style="width: 200px"
                      placeholder="请输入"
                      v-model="yhwybs"
                      clearable>
            </el-input>
          </div>
        </el-col>
&lt;!&ndash;        <el-col :span="6">
          <div class="grid-content">
            <span  style="margin-right: 5px;">用户昵称</span>
            <el-input style="width: 200px"
                      placeholder="请输入"
                      v-model="yhnc"
                      clearable>
            </el-input>
          </div></el-col>&ndash;&gt;
        <el-col :span="6">
          <div class="grid-content"><span  style="margin-right: 5px;">用户类型</span>
            <el-select style="width:200px" v-model="yhlx" placeholder="请选择">
              <el-option
                  v-for="item in yhlxOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select></div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content">
            <div class="block">
              <span  style="margin-right: 5px;">注册日期</span>
              <el-date-picker
                  v-model="zcrq"
                  type="datetime"
                  placeholder="选择日期时间"
                  align="right"
                  :picker-options="sjfwOptions">
              </el-date-picker>
            </div></div></el-col>
        <el-col :span="6">
          <div class="grid-content"><span  style="margin-right: 5px;">状态</span>
            <el-select style="width:200px" v-model="zt" placeholder="请选择">
              <el-option
                  v-for="item in ztOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select></div>
        </el-col>
      </el-row>
    </div>
    <div class="block" style="margin-top: 5px">
          <div class="grid-content">
            <el-button size="medium" type="primary" @click="getTable">查询</el-button>
&lt;!&ndash;            <el-button size="medium" type="primary">重置</el-button>&ndash;&gt;
          </div>
    </div>
    <div>
      <el-divider></el-divider>
    </div>-->
    <div>
      <el-row :gutter="20">
        <el-col :span="24"><el-divider content-position="center">用户信息</el-divider></el-col>
        <!--       <el-col :span="4"><div>
!--      <el-button type="primary" round size="small"  icon="el-icon-upload2" @click="openImport">导入</el-button>
           <el-button type="primary" round size="small"  icon="el-icon-download" @click="exportExcel">导出模板</el-button>
        </div></el-col>--></el-row>
    </div>
    <div>
    </div>
        <div>
          <el-table
              ref="filterTable"
              :data="tableData"
              height="550"
              border
              style="width: 100%">
            <el-table-column
                header-align="center"
                prop="id"
                label="用户编号"
                sortable
                width="120"
                align="center"
                column-key="date"
            >
            </el-table-column>
            <el-table-column
                header-align="center"
                prop="openid"
                label="用户唯一标识"
                align="center"
                width="250">
            </el-table-column>
<!--            <el-table-column
                header-align="center"
                prop="phone"
                sortable
                width="150"
                label="手机号">
            </el-table-column>-->
            <el-table-column
                header-align="center"
                prop="nickName"
                width="120"
                align="center"
                label="用户昵称">
            </el-table-column>
            <el-table-column
                header-align="center"
                prop="userType"
                sortable
                width="150"
                align="center"
                label="用户类型">
            </el-table-column>
            <el-table-column
                header-align="center"
                prop="registerDate"
                sortable
                width="180"
                align="center"
                label="注册日期">
            </el-table-column>
            <el-table-column
                header-align="center"
                prop="lastLoginDate"
                sortable
                width="180"
                align="center"
                label="最后登录日期"
            ></el-table-column>
            <el-table-column
                header-align="center"
                prop="status"
                sortable
                label="状态"
                 align="center"
            ></el-table-column>
<!--            <el-table-column
                header-align="center"
                label="头像"
            >
              <template slot-scope="scope">
                <el-avatar :size="60" src="https://empty">
                  <img :src="scope.avatarUrl"/>
&lt;!&ndash;                  <img src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png"/>&ndash;&gt;
                </el-avatar>
              </template>
            </el-table-column>-->
<!--            <el-table-column
                header-align="center"
                label="操作"
                width="100">
              <template slot-scope="scope">
                <el-button @click="handleClick(scope.row)" type="text" size="small">编辑</el-button>
              </template>
            </el-table-column>-->
          </el-table>
          <div class="block" style="float: right">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="[10, 25, 50, 100]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
            </el-pagination>
          </div>
        </div>
    <div>
      <el-dialog :visible="popupVisible"  @close="popupVisible = false">
        <el-form ref="form" :model="form" label-position="left" label-width="120px">
          <el-form-item label="用户编号：">
            <el-input v-model="form.id" readonly></el-input>
          </el-form-item>
          <el-form-item label="用户唯一标识：">
            <el-input v-model="form.openid" readonly></el-input>
          </el-form-item>
          <el-form-item label="手机号：">
            <el-input v-model="form.phone"></el-input>
          </el-form-item>
          <el-form-item label="用户昵称：">
            <el-input v-model="form.nickName"></el-input>
          </el-form-item>
          <el-form-item label="用户类型：">
            <div style="float: left">
            <el-radio-group v-model="form.userType">
              <el-radio :label="0">本网用户</el-radio>
              <el-radio :label="1">外网用户</el-radio>
            </el-radio-group>
            </div>
          </el-form-item>
          <el-form-item label="状态：">
            <div style="float: left">
            <el-radio-group v-model="form.status">
              <el-radio :label="0">可用</el-radio>
              <el-radio :label="1">禁用</el-radio>
              <el-radio :label="2">管理员</el-radio>
            </el-radio-group>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmitForm">保存</el-button>
            <el-button @click="onCancelForm">取消</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import WlglImport from '@/components/WlglImport.vue'
import {Form} from "element-ui";
export default {
  name: 'WlglDdxxlb',
  components: {
    WlglImport,
  },
  data() {
    return {
      form: {
        id: '',
        openid: '',
        phone: '',
        nickName: '',
        userType: '',
        status: ''
      },

      popupVisible: false, // 控制弹窗显示/隐藏
      popupContent: '', // 弹窗内容
      total:0,
      currentPage: 1,
      pageSize:10,

      zcrq: '',
      sjfwOptions: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '昨天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: '一周前',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }]
      },
      yhnc: '',
      yhwybs: '',
      yhlx: '',
      zt:'',
      ztOptions: [
        {
          value: '',
          label: ''
        },{
        value:  '0',
        label: '可用'
      }, {
        value: '1',
        label: '封禁'
      }, {
        value: '2',
        label: '管理员'
      }],
      yhlxOptions: [
        {
          value: '',
          label: ''
        },{
        value:  '0',
        label: '本网用户'
      }, {
        value: '1',
        label: '外网用户'
      }],
      user: {},
      tableData: []
    };
  },
  watch: {
  },
  methods: {
    getYmdhms(val){
      const dateTimeString = val;
      const dateObj = new Date(dateTimeString);

      const year = dateObj.getFullYear();
      const month = String(dateObj.getMonth() + 1).padStart(2, '0');
      const day = String(dateObj.getDate()).padStart(2, '0');
      const hours = String(dateObj.getHours()).padStart(2, '0');
      const minutes = String(dateObj.getMinutes()).padStart(2, '0');
      const seconds = String(dateObj.getSeconds()).padStart(2, '0');

      const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      return formattedDateTime;
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.getTable();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.getTable();
    },
    getTable(){
      // eslint-disable-next-line no-debugger
      debugger
      let this_ = this;
      let pageSize=this.pageSize;
      let pageNum=this.currentPage;
      let data = {
        "pageSize":pageSize,
        "pageNum":pageNum
      }
      const loading = this.$loading({
        lock: true,
        text: '正在加载中，请稍后',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.jquery.ajax({
        url: 'https://zhitotech.com:7002/dhl_logistics_applet/user/getUserPageList',
        method: 'POST',
        dataType: 'json',
        headers: {
          'Content-Type': 'application/json'
        },
        async: false, // 设置成同步请求
        data: JSON.stringify(data),
        success: function(response) {
          // 请求成功的处理逻辑
          let dataTemp=response.data.records;
          for (let i = 0; i < dataTemp.length; i++) {
            dataTemp[i].lastLoginDate = this_.$formatDateTime(dataTemp[i].lastLoginDate);
            dataTemp[i].registerDate = this_.$formatDateTime(dataTemp[i].registerDate);
            if (dataTemp[i].status==0){
              dataTemp[i].status='可用'
            }else if (dataTemp[i].status==1){
              dataTemp[i].status='封禁'
            }else if (dataTemp[i].status==2){
              dataTemp[i].status='管理员'
            }
            if (dataTemp[i].userType==0){
              dataTemp[i].userType='本网用户'
            }else if (dataTemp[i].userType==1){
              dataTemp[i].userType='外网用户'
            }
          }
          this_.tableData =dataTemp;
          this_.total = response.data.total;
          loading.close()
        },
        error: function(xhr, status, error) {
          // 请求失败的处理逻辑
          console.error(error);
          loading.close()
        }
      });
    },
    handleClick(content) {
      this.popupVisible = true;
      this.form = content;
      // this.getWLxx(content.orderId);
    },
    onCancelForm(content) {
      this.popupVisible = false;
      this.form={
            id: '',
            openid: '',
            phone: '',
            nickName: '',
            userType: '',
            status: ''
      }
      // this.getWLxx(content.orderId);
    },
    onSubmitForm(){
      let this_=this;
      let data = this.form;
      this.jquery.ajax({
        url: 'https://zhitotech.com:7002/dhl_logistics_applet/user/getUserPageList',
        method: 'POST',
        dataType: 'json',
        headers: {
          'Content-Type': 'application/json'
        },
        async: false, // 设置成同步请求
        data: JSON.stringify(data),
        success: function(response) {
          // 请求成功的处理逻辑
          console.log(response);
          var temp =[];
          // eslint-disable-next-line no-empty
          var logistics = response.data.orderLogistics;
          if (logistics.length>0){
            // eslint-disable-next-line no-empty
            for (let i = 0; i < logistics.length; i++) {
              temp.push({
                content:logistics[i].orderStatusName,
                timestamp:this_.getYmdhms(logistics[i].createDate)
              })
            }
            this_.activities = temp;
          }
          this_.getTable();
        },
        error: function(xhr, status, error) {
          // 请求失败的处理逻辑
          console.error(error);
        }
      });
    },
  },
  mounted() {
    this.getTable();
  },
}
</script>
<style scoped>
/* 在这里加入组件的样式 */
.margin-top {
  margin-top: 20px;
}
.activitiesBlock {
  padding: 20px;
  background-color: #f2f2f2;
  border-radius: 5px;
  margin-top: 20px;
}
.leftAlignedTimeline {
  text-align: left;
}

.leftAlignedTimeline .el-timeline {
  display: inline-block;
  text-align: left;
}
.center-align {
  text-align: center;
}
</style>