<template>
  <div id="rrapp" v-cloak>
    <div>
      <el-divider content-position="center">请选择搜索条件</el-divider>
    </div>
    <div class="block">
      <el-row :gutter="24">
        <el-col :span="6" >
          <div class="grid-content">
            <span  style="margin-right: 5px;">订单号</span>
            <el-input style="width: 200px"
                      placeholder="请输入订单号"
                      v-model="ddh"
                      clearable>
            </el-input>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content">
            <div class="block">
              <span  style="margin-right: 5px;">开始时间</span>
              <el-date-picker
                  v-model="kssj"
                  type="datetime"
                  placeholder="选择日期时间"
                  align="right"
                  :picker-options="sjfwOptions">
              </el-date-picker>
            </div></div></el-col>
        <el-col :span="6">
          <div class="grid-content">
            <div class="block">
              <span  style="margin-right: 5px;">结束时间</span>
              <el-date-picker
                  v-model="jssj"
                  type="datetime"
                  placeholder="选择日期时间"
                  align="right"
                  :picker-options="sjfwOptions">
              </el-date-picker>
            </div></div></el-col>
        <el-col :span="6">
          <div class="grid-content"><span  style="margin-right: 5px;">订单状态</span>
            <el-select style="width:200px" v-model="ddzt" placeholder="请选择" clearable>
              <el-option
                  v-for="item in ddztOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select></div>
        </el-col>
      </el-row>
    </div>
    <div class="block" style="margin-top: 5px">
          <div class="grid-content">
            <el-button size="medium" type="primary" @click="getTable('条件')">查询</el-button>
            <el-button size="medium" type="primary" @click="exportList">导出</el-button>
<!--            <el-button size="medium" type="primary">重置</el-button>-->
          </div>
    </div>
    <div>
      <el-divider></el-divider>
    </div>

      
    <el-table
        ref="filterTable"
        :data="tableData"
         :header-cell-style="{background: '#f3f6fd', color: '#555'}"
        style="height: 100%; width: 100%"
        size="small"
        border>
      <el-table-column
          header-align="center"
          align="center"
          prop="orderId"
          label="订单号"
          width="120"
      >
      </el-table-column>
      <el-table-column
          header-align="center"
          align="center"
          prop="orderName"
          label="订单名称"
          width="280">
      </el-table-column>
      <el-table-column
          header-align="center"
          align="center"
          prop="orderCount"
          width="70"
          label="数量">
      </el-table-column>
      <el-table-column
          header-align="center"
          align="center"
          prop="deliverName"
          width="160"
          label="发件人">
      </el-table-column>
      <el-table-column
          header-align="center"
          align="center"
          prop="createTime"
          width="90"
          label="提货日期">
      </el-table-column>
      <el-table-column
          header-align="center"
          align="center"
          prop="receiverAddress"
          width="245"
          label="收货地址">
      </el-table-column>
      <el-table-column
          header-align="center"
          align="center"
          prop="estimatedArvtime"
          width="100"
          label="预计送达日期">
      </el-table-column>

      <el-table-column
          header-align="center"
          align="center"
          prop="orderStatus"
          width="80"
          label="状态">
          <template slot-scope="scope" >
            <el-tag
                :type="getStatusInfoObject(scope.row.orderStatus).color"
                disable-transitions>
              {{getStatusInfoObject(scope.row.orderStatus).name}}
            </el-tag>
          </template>
      </el-table-column>

      <el-table-column prop="operate" label="操作" width="150">
        <template slot-scope="scope">
          <el-button size="small" type="success" @click="showPopup(scope.row)">详情</el-button>
        </template>
      </el-table-column>
    </el-table>

        <div class="block" style="float: right">
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[10, 25, 50, 100]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total">
          </el-pagination>
        </div>
      
    <div>
<!--      <el-dialog :visible.sync="dialogVisible" width="70%">
        <div class="demo-image__preview">
          <el-image
              style="width:100%"
              alt="预览"
              :src="currentImageUrl">
          </el-image>
        </div>
      </el-dialog>-->
      <el-dialog :visible.sync="popupVisible" width="80%">
        <el-descriptions class="margin-top" title="订单物流信息" :column="3" border>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-info"></i>
              订单号
            </template>
            {{popupContent.orderId}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-info"></i>
              订单名称
            </template>
            {{popupContent.orderName}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-coin"></i>
              订单数量
            </template>
            {{popupContent.orderCount}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-user"></i>
              收件人
            </template>
            {{popupContent.receiverName}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-mobile-phone"></i>
              收件人电话
            </template>
            {{popupContent.receiverPhone}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-location-outline"></i>
              收件人地址
            </template>
            {{popupContent.receiverAddress}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-user"></i>
              发件人
            </template>
            {{popupContent.deliverName}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-mobile-phone"></i>
              发件人电话
            </template>
            {{popupContent.deliverPhone}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-location-outline"></i>
              发件人地址
            </template>
            {{popupContent.deliverAddress}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-user"></i>
              状态
            </template> 
            <el-tag
                :type="getStatusInfoObject(popupContent.orderStatus).color"
                disable-transitions>
              {{getStatusInfoObject(popupContent.orderStatus).name}}
            </el-tag>
          </el-descriptions-item>
        </el-descriptions>
        <div class="activitiesBlock">
          <div class="leftAlignedTimeline">
            <el-timeline>
            <el-timeline-item
                v-for="(activity, index) in activities"
                :key="index"
                :icon="getStatusInfoObject(activity.orderStatus).icon"
                :color="getStatusInfoObject(activity.orderStatus).rawColor">
                <span style="font-weight: bolder;font-size: larger">
                  {{ getStatusInfoObject(activity.orderStatus).name }}<br/>
                </span>
                <div>
                  <span v-if="activity.orderStatus == 1">
                    发往: <b>{{ activity.theOrder.receiverAddress }}</b>, &nbsp;提货日期：{{$formatDate(activity.theOrder.createTime)}}
                  </span>
                  <span v-else-if="activity.orderStatus == 2">
                    已发货，我们将尽快送达。预计到达日期： <b>{{$formatDate(activity.theOrder.estimatedArvtime)}}</b>
                  </span>
                  <span v-else-if="activity.orderStatus == 3">
                    运输中，我们将尽快送达。预计到达日期： <b>{{$formatDate(activity.theOrder.estimatedArvtime)}}</b>
                  </span>
                  <span v-else-if="activity.orderStatus == 4">
                    签收时间: <b>{{$formatDate(activity.checkTime) }}</b>， 签收人：<b>{{activity.theOrder.remark}}</b>
                  </span>
                  <span v-else>
                    状态异常。 备注信息：<b>{{activity.theOrder.remark}}</b>
                  </span>
                </div>
            </el-timeline-item>
            </el-timeline>
          </div>
        </div>
      </el-dialog>
      <el-dialog :visible.sync="editPopupVisible" center title="运单状态修改" width="30%" class="custom-dialog-style">
        <el-form :model="form" label-position="left" label-width="100px">
          <el-form-item label="运单号">
            <el-input v-model="form.ydh" disabled></el-input>
          </el-form-item>
          <el-form-item label="运单状态">
            <el-select v-model="form.ydzt" placeholder="请选择" class="select-custom"  >
              <el-option v-for="item in ddztOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="状态名">
            <el-input v-model="form.ztm"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="editPopupVisible = false">取消</el-button>
          <el-button type="primary" @click="submitForm">确定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import WlglImport from '@/components/WlglImport.vue'
import WlglOfflineOrderImport from '@/components/WlglOfflineOrderImport.vue';


export default {
  name: 'WlglOffOrderForCustom',
  components: {
    WlglImport,
    WlglOfflineOrderImport
  },
  data() {
    return {
      serverUrl: this.$BASE_URL,
      dialogVisible: false,
      currentImageUrl: '',
      total:0,
      popupVisible: false, // 控制弹窗显示/隐藏
      popupContent: '', // 弹窗内容
      editPopupVisible: false, // 控制弹窗显示/隐藏
      editPopupContent: '', // 弹窗内容
      reverse: true,
      activities: [],
      wlglImportVisible:false,
      wlglOfflineOrderImportVisible:false,
      currentPage: 1,
      pageSize:10,
      kssj: '',
      jssj: '',
      sjfwOptions: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '昨天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: '一周前',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }]
      },
      form: {
        ydh: '',
        ydzt: '',
        ztm: ''
      },
      ddh: '',
      ddztOptions: [
      {
        value: '0',
        label: '异常'
      }, {
        value: '1',
        label: '发货'
      } , {
        value: '2',
        label: '运输'
      }, {
        value: '3',
        label: '配送'
      },
      {
        value: '4',
        label: '签收'
      }],
      ddzt: '',
      user: {},
      tableData: []
    };
  },
  watch: {
  },
  methods: {

    getStatusInfoObject(code) {
      const statusCodes = {
        '0': {name:'异常', color:'danger', icon:'iconfont icon-yichangwuliu', rawColor:'#F56C6C'},
        '1': {name:'发货', color:'primary', icon:'iconfont icon-baoguo_fahuo_o', rawColor:'#409EFF'},
        '2': {name:'运输', color:'warning', icon:'iconfont icon-yunshuzhongwuliu', rawColor:'#E6A23C'},
        '3': {name:'配送', color:'warning', icon:'iconfont icon-yunshuzhongwuliu', rawColor:'#E6A23C'},
        '4': {name:'签收', color:'success', icon:'iconfont icon-yiqianshou', rawColor:'#67C23A'},
      };
      return statusCodes[code] || {name:'未知', color:'danger', icon:'iconfont icon-yichangwuliu', rawColor:'red'};
    },

    openImageDialog(url) {
      this.currentImageUrl = url;
      this.dialogVisible = true;
    },
    getYmdhms(val){
      const dateTimeString = val;
      const dateObj = new Date(dateTimeString);

      const year = dateObj.getFullYear();
      const month = String(dateObj.getMonth() + 1).padStart(2, '0');
      const day = String(dateObj.getDate()).padStart(2, '0');
      const hours = String(dateObj.getHours()).padStart(2, '0');
      const minutes = String(dateObj.getMinutes()).padStart(2, '0');
      const seconds = String(dateObj.getSeconds()).padStart(2, '0');

      const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      return formattedDateTime;
    },
    /*********      表格处理    ********/
    resetDateFilter:function() {
      this.$refs.filterTable.clearFilter('date');
    },
    clearFilter:function() {
      this.$refs.filterTable.clearFilter();
    },
    formatter:function (row, column) {
      return row.address;
    },
    filterTag:function (value, row) {
      return row.tag === value;
    },
    filterHandler:function (value, row, column) {
      const property = column['property'];
      return row[property] === value;
    },
    exportExcel() {
      window.open(this.serverUrl + "/dhl_logistics_applet/manager/order/export")
    },
    exportOfflineExcel() {
      window.open(this.serverUrl + "/dhl_logistics_applet/offorder/export")
    },
    openImport(){
      this.$confirm('此操作将导入订单信息, 请确保已根据导出的模板填写信息?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        this.wlglImportVisible = true;
      }).catch(() => {
      });
    },
    openOfflineOrderImport(){
      this.$confirm('此操作将导入订单信息, 请确保已根据导出的模板填写信息?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        this.wlglOfflineOrderImportVisible = true;
      }).catch(() => {
      });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.getTable('');
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.getTable('');
    },
    isNull(val) {
      if (val == null || val == "" || val == "null" || val == undefined) {
        return true;
      }
      return false;
    },
    getTable(text){
      // eslint-disable-next-line no-debugger

      let self = this;

      let endTime = this.isNull(this.jssj)  ? "":this.getYmdhms(this.jssj);
      let createTime = this.isNull(this.kssj) ? "":this.getYmdhms(this.kssj);
      let orderId =this.ddh;
      let orderStatus =this.ddzt;
      if (text=='条件'){
        this.pageSize=10;
        this.currentPage=1;
      }
      let pageSize=this.pageSize;
      let pageNum=this.currentPage;
      let data = {
        "endTime": endTime,
        "createTime": createTime,
        "orderId": orderId,
        "orderStatus": orderStatus,
        "pageSize":pageSize,
        "pageNum":pageNum
      }
      const loading = this.$loading({
        lock: true,
        text: '正在加载中，请稍后',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.jquery.ajax({
        url: this.serverUrl + '/dhl_logistics_applet/offorder/getOrderPageList',
        method: 'POST',
        dataType: 'json',
        headers: {
          'Content-Type': 'application/json'
        },
        async: false, // 设置成同步请求
        data: JSON.stringify(data),
        success: function(response) {
          // 请求成功的处理逻辑
          let dataTemp=response.data.records;
          for (let i = 0; i < dataTemp.length; i++) {
            dataTemp[i].createTime = self.$formatDate(dataTemp[i].createTime);
            dataTemp[i].updateTime = self.$formatDate(dataTemp[i].updateTime);
            dataTemp[i].estimatedArvtime = self.$formatDate(dataTemp[i].estimatedArvtime)
          }
          self.tableData =dataTemp;
          self.total =response.data.total;
          loading.close()
        },
        error: function(xhr, status, error) {
          // 请求失败的处理逻辑
          console.error(error);
        }
      });
    },
    showPopup(content) {
      this.popupVisible = true;
      this.popupContent = content;
      this.getWLxx(content.orderId);
    },
    editPopup(content) {
      // eslint-disable-next-line no-debugger
      debugger
      this.editPopupVisible = true;
      this.editPopupContent = content;
      this.form.ydh = content.orderId;
      this.form.ydzt = content.orderStatus + '';
      this.form.ztm = content.remark
    },
    getWLxx(val){
      let this_=this;
      let data ={
        "orderId": val
      }
      this.jquery.ajax({
        url: this.serverUrl + '/dhl_logistics_applet/offorder/queryOrderDetail',
        method: 'POST',
        dataType: 'json',
        headers: {
          'Content-Type': 'application/json'
        },
        async: false, // 设置成同步请求
        data: JSON.stringify(data),
        success: function(response) {
          // 请求成功的处理逻辑
          console.log(response);
          var temp =[];
          // eslint-disable-next-line no-empty
          var logistics = response.data.orderLogistics;
          if (logistics.length>0){
            // eslint-disable-next-line no-empty
            for (let i = 0; i < logistics.length; i++) {
              temp.push({
                orderStatus:logistics[i].orderStatus,
                timestamp:this_.getYmdhms(logistics[i].createDate),
                theOrder:response.data,
                checkTime: logistics[i].checkTime
              })
            }
            this_.activities = temp;
            console.log(temp)
          }
        },
        error: function(xhr, status, error) {
          // 请求失败的处理逻辑
          console.error(error);
        }
      });
    },
    exportList(){
      let this_=this;
      let endTime = this.isNull(this.jssj)  ? "":this.getYmdhms(this.jssj);
      let createTime = this.isNull(this.kssj) ? "":this.getYmdhms(this.kssj);
      let orderId =this.ddh;
      let orderStatus =this.ddzt;
      let data = {
        "endTime": endTime,
        "createTime": createTime,
        "orderId": orderId,
        "orderStatus": orderStatus
      }
      // A标签导出方法：通过生成一个A标签然后触发后台传过来的下载链接完成导出
      //核心注意需要给请求的格式改为：responseType: "blob",
      this.axios
          .post(
              this.serverUrl + '/dhl_logistics_applet/offorder/exportDataList', data, {
                token: true,
                responseType: "blob",
              }
          )
          .then((response) => {
            const blob = new Blob([response.data], { type: 'application/vnd.ms-excel' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = '订单信息'+this_.getYmdhms(new Date())+'.xlsx';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          })
          .catch((err) => {

          });
    },
    updateParentData(newValue){
      this.wlglImportVisible=false;
      this.wlglOfflineOrderImportVisible=false;
    },
    submitForm(){
      let this_=this;
      let data ={
        "orderId": this.form.ydh,
        "orderStatus": this.form.ydzt,
        "remark": this.form.ztm,
      }
      const loading = this.$loading({
        lock: true,
        text: '正在修改中，请稍后',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      setTimeout(() => {
        this_.jquery.ajax({
        url: this.serverUrl + '/dhl_logistics_applet/offorder/updateOrderStatus',
        method: 'POST',
        dataType: 'json',
        headers: {
          'Content-Type': 'application/json'
        },
        async: false, // 设置成同步请求
        data: JSON.stringify(data),
        success: function(response) {
          // eslint-disable-next-line no-debugger
          debugger
          // 请求成功的处理逻辑
          console.log(response);
          loading.close();
          this_.editPopupVisible=false;
          this_.getTable('条件');
        }
      });},10)

    }
  },
  mounted() {
    this.getTable('条件');
  },
}
</script>
<style scoped>
/* 在这里加入组件的样式 */
.margin-top {
  margin-top: 20px;
}
.activitiesBlock {
  padding: 20px;
  background-color: #f2f2f2;
  border-radius: 5px;
  margin-top: 20px;
}
.leftAlignedTimeline {
  text-align: left;
}

.leftAlignedTimeline .el-timeline {
  display: inline-block;
  text-align: left;
}
.custom-dialog-style {
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}

.select-custom {
  width: 100%;
}

.dialog-footer {
  text-align: right;
}

.el-form-item__label {
  width: 100px; /* 控制标签宽度 */
  text-align: left; /* 控制标签对齐方式 */
}
</style>