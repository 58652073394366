<template>
  <div>
    <el-upload
        class="upload-demo"
        ref="upload"
        :action="this.$BASE_URL + '/dhl_logistics_applet/manager/order/import'"
        :on-preview="handlePreview"
        :on-remove="handleRemove"
        :on-success = "submitUploadSuccess"
        :on-error = "submitUploadError"
        :file-list="fileList"
        :auto-upload="false">
      <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
      <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">上传到服务器</el-button>
      <div slot="tip" class="el-upload__tip">只支持提供的模板格式导入信息</div>
    </el-upload>
  </div>
</template>
<script>
export default {
  name: 'WlglImport',
  props:{
    getTable:{
      type:Function,
      require:true
    },
  },
  data() {
    return {
      fileList: [],
      loading:true
    };
  },
  methods: {
    submitUpload() {
      this.$refs.upload.submit();
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    submitUploadSuccess(response, file, fileList){
      // 清空文件列表
      this.$refs.upload.clearFiles();
      this.$emit('update', false);
      this.getTable("条件");
    },
    submitUploadError(){
    }
  }
}
</script>