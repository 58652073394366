import Vue from 'vue'
import Router from 'vue-router'
import WlglImport from '@/components/WlglImport.vue'
import LoginForm from '@/components/LoginForm.vue'
import WlglHome from '@/components/WlglHome.vue'

Vue.use(Router)

const router =  new Router({
    mode:'history',
    routes: [
        {
            path: '/login',
            name: 'LoginForm',
            component: LoginForm
        },
        {
            path: '/home',
            name: 'WlglHome',
            component: WlglHome
        }
    ]
})

// 导航守卫
// 参数1 : to 目标路由对象
// 参数2 : from 来源路由对象
// 参数3 : next() 下一步
router.beforeEach((to, from, next) => {
    // 1. 判断是不是登录页面
    // 是登录页面
    if(to.path === '/login') {
        next()
    } else {
        // 不是登录页面
        // 2. 判断 是否登录过
        let token = sessionStorage.getItem('token')
        if (token == "完成"){
            next()
        }else{
            next('/login')
        }
    }
})

export default router