import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import axios from 'axios'
import jquery from 'jquery';
import router from './router/router'
import VueRouter from "vue-router";
import BaiduMap from 'vue-baidu-map';
import * as echarts from 'echarts';
import moment from 'moment';

Vue.use(BaiduMap, {
  ak: 'DcCH84Qvi26SE4iQBdRuTXgJPOzVjoy4'//百度地图密钥
});
// axios.defaults.baseURL = 'https://zhitotech.com:9088/'
Vue.prototype.echarts = echarts
Vue.prototype.axios = axios
Vue.prototype.$axios =axios;
Vue.prototype.jquery = jquery
Vue.use(ElementUI);
Vue.config.productionTip = false

const BASE_URL = process.env.NODE_ENV === 'development'
  ? 'https://localhost:7002' // 本地地址
  : 'https://zhitotech.com:7002'; // 远程地址

  Vue.prototype.$BASE_URL = BASE_URL;

// 获取原型对象push函数
const originalPush = VueRouter.prototype.push

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location){
  return originalPush.call(this , location).catch(err=>err)
}
Vue.prototype.$formatDateTime = function(milliseconds) {
  const date = new Date(milliseconds);

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');

  const hour = date.getHours().toString().padStart(2, '0');
  const minute = date.getMinutes().toString().padStart(2, '0');
  const second = date.getSeconds().toString().padStart(2, '0');

  const formattedDateTime = `${year}-${month}-${day} ${hour}:${minute}:${second}`;

  return formattedDateTime;
}


Vue.prototype.$formatDate = function(dateInput) {
  if (!dateInput) {
     return ""; 
  }
  return moment(dateInput).format('YYYY-MM-DD'); 
};


// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location){
  return originalReplace.call(this , location).catch(err=>err)
}
Vue.prototype.$wlglUser = {
  userName:'',
  realName:'',
}
new Vue({
  router: router,
  render: h => h(App),
}).$mount('#app')

