<template>
  <div id="app">
<!--    <WlglImport/>-->
<!--    <LoginForm/>-->
    <router-view/>
  </div>
</template>

<script>
// import WlglImport from './components/WlglImport.vue'
// import LoginForm from './components/LoginForm.vue'

export default {
  name: 'App',
  // components: {
  //   LoginForm
  // }
  created() {
    // eslint-disable-next-line no-debugger
    debugger
    // this.$router.push("/login")
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
  overflow: auto; /* 如果需要在内容溢出时显示滚动条 */
}

html, body {
  margin: 0;
  padding: 0;
  overflow: hidden; /* 禁止滚动条 */

}
</style>
